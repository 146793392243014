	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Slider functionality
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/

import  './vendor/flickity.min';

(function($) {
	$('.slider').each(function () {
	  let _this = $(this);
	  var args = {
	    pageDots: false,
	    wrapAround: true,
	    cellSelector: '.slide',
	    percentPosition: true,
	    contain: true,
	    setGallerySize: false,
	    resize: true,
	    imagesLoaded: true,
	    cellAlign: 'left',
	    freeScroll: false,
	    prevNextButtons: false,
	    // autoPlay: 4000,
	    // selectedAttraction: 0.008,
	    // friction: 0.16
	  };

	  var $carousel = _this.flickity(args);
	  //Destroy
	  // $carousel.flickity('destroy');
	  //Re-init
	  $carousel.flickity(args);
	  // $carousel.flickity('reloadCells')

	  // $(this).on('click', function () {
	  // 	$carousel.flickity('next')
	  // });

	  // $carousel.on('dragStart.flickity', () => $carousel.find('.slide').css('pointer-events', 'none'));
	  // $carousel.on('dragEnd.flickity', () => $carousel.find('.slide').css('pointer-events', 'all'));
	});

	$('.info-slider').each(function () {
	  let _this = $(this);
	  var args = {
	    pageDots: false,
	    wrapAround: true,
	    cellSelector: '.slide',
	    percentPosition: true,
	    contain: true,
	    setGallerySize: false,
	    resize: true,
	    imagesLoaded: true,
	    cellAlign: 'left',
	    freeScroll: false,
	    prevNextButtons: false,
	    autoPlay: 4000,
	    selectedAttraction: 0.008,
	    friction: 0.16
	  };

	  var $carousel = _this.flickity(args);
	  //Destroy
	  // $carousel.flickity('destroy');
	  //Re-init
	  $carousel.flickity(args);
	  // $carousel.flickity('reloadCells');
	});

	$('.gallery-slider').each(function () {
	  let _this = $(this);
	  var args = {
	    pageDots: false,
	    wrapAround: true,
	    cellSelector: '.wp-block-image',
	    percentPosition: true,
	    contain: true,
	    setGallerySize: true,
	    resize: true,
	    imagesLoaded: true,
	    cellAlign: 'left',
	    freeScroll: false,
	    prevNextButtons: false,
	    autoPlay: 3000,
	    selectedAttraction: 0.008,
	    friction: 0.16
	  };

	  var $carousel = _this.flickity(args);
	  $carousel.flickity(args);

	});

    $('.member-slider').each(function () {
        let _this = $(this);
        var args = {
          pageDots: true,
          wrapAround: true,
          cellSelector: '.member-insight',
          percentPosition: true,
          contain: true,
          setGallerySize: true,
          resize: true,
          imagesLoaded: true,
          cellAlign: 'left',
          freeScroll: false,
          prevNextButtons: false,
          autoPlay: 3000,
          selectedAttraction: 0.008,
          friction: 0.16
        };
  
        var $carousel = _this.flickity(args);
        $carousel.flickity(args);
  
      });

      $('.media-1-slider').each(function () {
        let _this = $(this);
        var args = {
          pageDots: true,
          wrapAround: true,
          cellSelector: '.news',
          percentPosition: true,
          contain: true,
          setGallerySize: true,
          resize: true,
          imagesLoaded: true,
          cellAlign: 'left',
          freeScroll: false,
          prevNextButtons: true,
          autoPlay: false,
          selectedAttraction: 0.008,
          friction: 0.16,
          groupCells: true
        };
  
        var $carousel = _this.flickity(args);
        $carousel.flickity(args);
  
      });

      $('.media-2-slider').each(function () {
        let _this = $(this);
        var args = {
          pageDots: false,
          wrapAround: true,
          cellSelector: '.media-file',
          percentPosition: true,
          contain: true,
          setGallerySize: true,
          resize: true,
          imagesLoaded: true,
          cellAlign: 'left',
          freeScroll: false,
          prevNextButtons: true,
          autoPlay: false,
          selectedAttraction: 0.008,
          friction: 0.16
        };
  
        var $carousel = _this.flickity(args);
        $carousel.flickity(args);
  
      });

      $('.linkedin-slider').each(function () {
        let _this = $(this);
        var args = {
          pageDots: false,
          wrapAround: true,
          cellSelector: '.grid-sizer-linkedin-page-post > span',
          percentPosition: true,
          contain: true,
          setGallerySize: true,
          resize: true,
          imagesLoaded: true,
          cellAlign: 'left',
          freeScroll: false,
          prevNextButtons: true,
          autoPlay: false,
          selectedAttraction: 0.008,
          friction: 0.16
        };
  
        var $carousel = _this.flickity(args);
        $carousel.flickity(args);
  
      });

      $('.podcast-slider').each(function () {
        let _this = $(this);
        var args = {
          pageDots: false,
          wrapAround: true,
          cellSelector: 'li.wp-block-post',
          percentPosition: true,
          contain: true,
          setGallerySize: true,
          resize: true,
          imagesLoaded: true,
          cellAlign: 'left',
          freeScroll: false,
          prevNextButtons: true,
          autoPlay: false,
          selectedAttraction: 0.008,
          friction: 0.16
        };
  
        var $carousel = _this.flickity(args);
        $carousel.flickity(args);
  
      });

})( jQuery );