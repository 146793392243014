console.log('🥑 %cMade by Monk', 'background: #616a2e; color: #f4e9e2; padding: 5px 17px; border-radius: 3px;');
console.log(' %chttps://monk.com.au ', 'padding: 5px 13px;');

import './skip-link-focus-fix';
import './smoothscroll';
import './navigation';
import './scrollyclasses';
import './viewport-height';
import './fw-video';
import './sliders';
import './accordions';
import './fancybox';
import './gsapanims';

jQuery(function ($) {

	//page
	var $hamburger = $(".hamburger"),
		$site = $("body"),
		$menu = $(".main-navigation"),
		$menuitems = $(".menu-item"),
        $vision = $("#vision"),
        $visionpara = $("#vision-para"),
        $purpose = $("#purpose"),
        $purposepara = $("#purpose-para"),
        $mission = $("#mission"),
        $missionpara = $("#mission-para"),
		$screenOverlay = $(".screen-overlay"),
		$search = $(".searcher");

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Mobile Menu
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	$hamburger.on("click", function () {
		$hamburger.toggleClass("is-active");
		$site.toggleClass("menu-open");
		// if ( $('body').hasClass('menu-open') ) {
		// 	$('#mobile-menu .label').text('Close')
		// } else {
		// 	$('#mobile-menu .label').text('Menu')
		// }
	});

	//close menu with an outside click (basically anywhere on .site-content)
	function closeMenu() {
		$site.removeClass("menu-open");
		$menu.removeClass("toggled");
		$menuitems.removeClass('toggled-on');
		$hamburger.removeClass("is-active");
	}

	$screenOverlay.on('click', closeMenu);

	$(document).on('keydown', function (e) {
		if (e.which == 27) {
			closeMenu();
		}
	});

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Animate elements in on scroll
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	// $('.animate').on('inview', function (event, isInView) {
	// 	if (isInView) {
	// 		$(this).addClass('fadein');
	// 	}
	// });
	// AOS.init();

    $search.on("click", function () {
		$site.toggleClass("searchable");
	});


    let visionClicked = false;
    let missionClicked = false;
    let purposeClicked = false;

    $vision.on("click", function () {
        if ( !$visionpara.hasClass("clicked") ) {
		    $vision.toggleClass("on");
            $visionpara.toggleClass("clicked");
            visionClicked = true;
        }
        if ( missionClicked === true ) {
            missionClicked = false;
            $mission.removeClass("on");
        }
        if ( purposeClicked === true ) {
            purposeClicked = false;
            $purpose.removeClass("on");
        }
	}).on( "mouseenter", function() {
        $visionpara.toggleClass("hovered");

        if ( $missionpara.hasClass("clicked") ) {
            $missionpara.removeClass("clicked");
        }
        if ( $purposepara.hasClass("clicked") ) {
            $purposepara.removeClass("clicked");
        }
    }).on( "mouseleave", function() {
        $visionpara.removeClass("hovered");

        if ( missionClicked === true ) {
            $missionpara.toggleClass("clicked");
        }
        if ( purposeClicked === true ) {
            $purposepara.toggleClass("clicked");
        }
    });

    $mission.on("click", function () {
        if ( !$missionpara.hasClass("clicked") ) {
            $mission.toggleClass("on");
		    $missionpara.toggleClass("clicked");
            missionClicked = true;
        }
        if ( visionClicked === true ) {
            visionClicked = false;
            $vision.removeClass("on");
        }
        if ( purposeClicked === true ) {
            purposeClicked = false;
            $purpose.removeClass("on");
        }
	}).on( "mouseenter", function() {
        $missionpara.toggleClass("hovered");

        if ( $visionpara.hasClass("clicked") ) {
            $visionpara.removeClass("clicked");
        }
        if ( $purposepara.hasClass("clicked") ) {
            $purposepara.removeClass("clicked");
        }
    }).on( "mouseleave", function() {
        $missionpara.removeClass("hovered");

        if ( visionClicked === true ) {
            $visionpara.toggleClass("clicked");
        }
        if ( purposeClicked === true ) {
            $purposepara.toggleClass("clicked");
        }
    });

    $purpose.on("click", function () {
        if ( !$purposepara.hasClass("clicked") ) {
            $purpose.toggleClass("on");
		    $purposepara.toggleClass("clicked");
            purposeClicked = true;
        }
        if ( missionClicked === true ) {
            missionClicked = false;
            $mission.removeClass("on");
        }
        if ( visionClicked === true ) {
            visionClicked = false;
            $vision.removeClass("on");
        }
	}).on( "mouseenter", function() {
		$purposepara.toggleClass("hovered");

        if ( $visionpara.hasClass("clicked") ) {
            $visionpara.removeClass("clicked");
        }
        if ( $missionpara.hasClass("clicked") ) {
            $missionpara.removeClass("clicked");
        }
    }).on( "mouseleave", function() {
        $purposepara.removeClass("hovered");

        if ( visionClicked === true ) {
            $visionpara.toggleClass("clicked");
        }
        if ( missionClicked === true ) {
            $missionpara.toggleClass("clicked");
        }
    });

    // $(".research-list-content").appendTo($(".content-hangar"));

    $(".research-list").on( "mouseenter", "li", function() {

        $('.research-list-content').each( function() {
            $(this).removeClass('show');
        });

        $(".research-list>li").each( function() {
            $(this).removeClass('hover');
        });

        document.getElementById($(this).data('target')).classList.add('show');
        $(this).addClass('hover');

    });

    if (window.matchMedia("(max-width: 767px)").matches)
    { 
        
        $(".block-content").appendTo($(".mobile-hangar"));

        $(".research-list").on( "click", "li", function() {

            $([document.documentElement, document.body]).animate({
                scrollTop: ($(".content-hangar").offset().top) - 50
            }, 800);
    
        });

    }

    // $(document).ready(function(){

    //     $(".sk_branding").css("display","none");

    // });

    // function destroyThis()
    // {
 
    //     var delayTime = 6000;
    //     setTimeout(function() {
    //         document.querySelector(".sk_branding").style.display = "none";

    //     }, delayTime );

    // }

    // destroyThis();

});